import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import BlogCard from "./BlogsCards";
// import Particle from "../Particle";
import "../../style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import gzt from "../../Assets/Projects/xpat.jpeg";
import dg from "../../Assets/Projects/rbc.jpeg";
import snfcc from "../../Assets/Projects/yum.jpeg";
// import emotion from "../../Assets/Projects/emotion.jpeg";
// import editor from "../../Assets/Projects/codeEditor.png";
// import eeg from "../../Assets/Projects/eeg.gif";
// import suicide from "../../Assets/Projects/suicide.png";
// import algo from "../../Assets/Projects/algo.png";
// import plant from "../../Assets/Projects/plant.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/*<Particle />*/}
      <Container>
        <h1 className="project-heading">
          Organizations I have <strong className="purple">collaborated with</strong>
        </h1>
        <p style={{ color: "white" }}>
         Allow me to introduce some of the remarkable names gracing my portfolio.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dg}
              isBlog={false}
              title="Royal Bank of Canada"
              description="I have had the privilege of crafting pivotal microservices for Royal Bank of Canada's Conversational AI initiative. These services are instrumental in delivering seamless and efficient interactions, enhancing the bank's customer experiences through cutting-edge technology and innovation."
              link="https://www.rbcroyalbank.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gzt}
              isBlog={false}
              title="IAMEXPAT.NL"
              description="I've had the privilege of architecting bespoke microservices for iamexpat.nl, elevating their digital platform. These services are instrumental in delivering an immersive user journey, streamlining content delivery, and guaranteeing flawless operation within this thriving community hub."
              link="https://www.iamexpat.nl/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={snfcc}
              isBlog={false}
              title="Yum Foods"
              description="I've had the honor of spearheading the development of specialized microservices for Yum Foods, the parent company of renowned brands like Taco Bell and KFC. These microservices have revolutionized their ordering system, optimizing efficiency, and ensuring a delightful dining experience for millions of customers."
              link="https://www.yum.com/"
            />
          </Col>
        </Row>
        {/*<h1 className="project-heading">*/}
        {/*  My Recent <strong className="purple">Blog </strong> Posts*/}
        {/*</h1>*/}
        {/*<p style={{ color: "white" }}>Do give a read to some of my blogs</p>*/}
        {/*<Row style={{ justifyContent: "center" }}>*/}
        {/*  <Col md={4} className="blog-card">*/}
        {/*    <BlogCard*/}
        {/*      imgPath={algo}*/}
        {/*      link=""*/}
        {/*      title="Cracking Interview"*/}
        {/*      site="gitbook.com"*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col md={4} className="blog-card">*/}
        {/*    <BlogCard*/}
        {/*      imgPath={plant}*/}
        {/*      link="https://medium.com/jovianml/plant-ai-c8fc95ed90e6"*/}
        {/*      title="Plant AI"*/}
        {/*      site="medium.com"*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Container>
    </Container>
  );
}

export default Projects;
